// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistoryItem_container__iD66S {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eff4f8;
  padding: 0.625rem 0.938rem;
  margin-top: 0.75rem;
  border-radius: 8px;
}
.HistoryItem_text_container__LZ4zF {
  display: flex;
  gap: 0.625rem;
  flex: 1 1;
  align-items: center;
}
.HistoryItem_text__3DO9I {
  font-size: 0.875rem;
  color: #262626;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10.625rem;
}

.HistoryItem_input__7ZP8f {
  font-size: 0.875rem;
  max-width: 80%;
  flex: 1 1;
  background-color: #eff4f8;
  padding: 0 0.313rem;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/History/HistoryItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,0BAA0B;EAC1B,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,aAAa;EACb,SAAO;EACP,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;;EAEhB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,SAAO;EACP,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #eff4f8;\n  padding: 0.625rem 0.938rem;\n  margin-top: 0.75rem;\n  border-radius: 8px;\n}\n.text_container {\n  display: flex;\n  gap: 0.625rem;\n  flex: 1;\n  align-items: center;\n}\n.text {\n  font-size: 0.875rem;\n  color: #262626;\n  font-weight: 500;\n\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 10.625rem;\n}\n\n.input {\n  font-size: 0.875rem;\n  max-width: 80%;\n  flex: 1;\n  background-color: #eff4f8;\n  padding: 0 0.313rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HistoryItem_container__iD66S`,
	"text_container": `HistoryItem_text_container__LZ4zF`,
	"text": `HistoryItem_text__3DO9I`,
	"input": `HistoryItem_input__7ZP8f`
};
export default ___CSS_LOADER_EXPORT___;
