// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Product_brand_product__ZNsQC {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Product_brand_product__ZNsQC img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Product_status__tlt1l {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.Product_action_btn__-VrUU {
  padding: 0 8px !important;
}
.Product_competitor__8KO2- {
  padding: 3px;
}

`, "",{"version":3,"sources":["webpack://./src/luminaire/Settings/Product/Product.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd","sourcesContent":[".brand_product {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.brand_product img {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n}\n.status {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n}\n.action_btn {\n  padding: 0 8px !important;\n}\n.competitor {\n  padding: 3px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand_product": `Product_brand_product__ZNsQC`,
	"status": `Product_status__tlt1l`,
	"action_btn": `Product_action_btn__-VrUU`,
	"competitor": `Product_competitor__8KO2-`
};
export default ___CSS_LOADER_EXPORT___;
