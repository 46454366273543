// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Discovery_content_section__9VOmB {
  display: grid;
  grid-template-rows: auto 11.1rem 1fr;
  margin: 0 1rem;
}

.Discovery_container__\\+Vwoz {
  display: grid;
  grid-template-columns: 7fr 3fr;
  column-gap: 1.25rem;
}

.Discovery_content__hjEV8 {
  flex: 6.5 1;
  display: flex;
  flex-direction: column;
}

.Discovery_result__HOc6P {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 1.25rem;
}

.Discovery_history__JqmVA {
  flex: 2.5 1;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.Discovery_action_btn_group__sLZO3 {
  display: flex;
  justify-content: flex-end;
  gap: 1.25rem;
  margin-bottom: 1.875rem;
  margin-right: 1.25rem;
}

.Discovery_section_summary__WfHlP {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  background-color: white;
  padding: 1.25rem;
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.Discovery_section__O3di5 {
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.Discovery_attributes_com_section__LZWU9 {
  display: flex;
  gap: 1.875rem;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Discovery.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAS;EACT,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAS;EACT,mDAAmD;EACnD,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,mDAAmD;AACrD;;AAEA;EACE,kBAAkB;EAClB,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,aAAa;AACf","sourcesContent":[".content_section {\n  display: grid;\n  grid-template-rows: auto 11.1rem 1fr;\n  margin: 0 1rem;\n}\n\n.container {\n  display: grid;\n  grid-template-columns: 7fr 3fr;\n  column-gap: 1.25rem;\n}\n\n.content {\n  flex: 6.5;\n  display: flex;\n  flex-direction: column;\n}\n\n.result {\n  display: flex;\n  flex-direction: column;\n  gap: 1.25rem;\n  margin-top: 1.25rem;\n}\n\n.history {\n  flex: 2.5;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n  border-radius: 8px;\n}\n\n.action_btn_group {\n  display: flex;\n  justify-content: flex-end;\n  gap: 1.25rem;\n  margin-bottom: 1.875rem;\n  margin-right: 1.25rem;\n}\n\n.section_summary {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1.25rem;\n  background-color: white;\n  padding: 1.25rem;\n  border-radius: 8px;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n}\n\n.section {\n  border-radius: 8px;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n}\n\n.attributes_com_section {\n  display: flex;\n  gap: 1.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content_section": `Discovery_content_section__9VOmB`,
	"container": `Discovery_container__+Vwoz`,
	"content": `Discovery_content__hjEV8`,
	"result": `Discovery_result__HOc6P`,
	"history": `Discovery_history__JqmVA`,
	"action_btn_group": `Discovery_action_btn_group__sLZO3`,
	"section_summary": `Discovery_section_summary__WfHlP`,
	"section": `Discovery_section__O3di5`,
	"attributes_com_section": `Discovery_attributes_com_section__LZWU9`
};
export default ___CSS_LOADER_EXPORT___;
