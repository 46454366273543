// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandSpecItem_title__QQN83 {
  font-size: 0.813rem;
  font-weight: 700;
  color: #626262;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 0.75rem;
}

.BrandSpecItem_item__LR4aN {
  display: flex !important;
  padding: 0.15rem 0 !important;
}
.BrandSpecItem_item_avatar__AIdmW {
  display: flex;
  justify-content: center;
  min-width: 1.875rem !important;
  margin-top: 0.375rem;
}

.BrandSpecItem_avatar__nhOPc {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.BrandSpecItem_llm__DrAtQ {
  font-size: 0.688rem;
  color: #7a7a7a;
  font-weight: 600;
  padding-right: 0.313rem;
}
.BrandSpecItem_snippet__XlGVp {
  font-size: 0.75rem;
  color: #626262;
}

.BrandSpecItem_list__\\+e7xo {
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/BrandSpecItem/BrandSpecItem.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,gCAAgC;EAChC,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,6BAA6B;AAC/B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".title {\n  font-size: 0.813rem;\n  font-weight: 700;\n  color: #626262;\n  border-bottom: 1px solid #D9D9D9;\n  padding-bottom: 0.75rem;\n}\n\n.item {\n  display: flex !important;\n  padding: 0.15rem 0 !important;\n}\n.item_avatar {\n  display: flex;\n  justify-content: center;\n  min-width: 1.875rem !important;\n  margin-top: 0.375rem;\n}\n\n.avatar {\n  width: 1.125rem !important;\n  height: 1.125rem !important;\n}\n\n.llm {\n  font-size: 0.688rem;\n  color: #7a7a7a;\n  font-weight: 600;\n  padding-right: 0.313rem;\n}\n.snippet {\n  font-size: 0.75rem;\n  color: #626262;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `BrandSpecItem_title__QQN83`,
	"item": `BrandSpecItem_item__LR4aN`,
	"item_avatar": `BrandSpecItem_item_avatar__AIdmW`,
	"avatar": `BrandSpecItem_avatar__nhOPc`,
	"llm": `BrandSpecItem_llm__DrAtQ`,
	"snippet": `BrandSpecItem_snippet__XlGVp`,
	"list": `BrandSpecItem_list__+e7xo`
};
export default ___CSS_LOADER_EXPORT___;
