// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopProduct_card__lWNp7 {
  padding: 0 !important;
}

.TopProduct_table_head__B\\+X0K {
  background: #eff4f8;
}

.TopProduct_table_head_cell__fYs6e {
  /* border-bottom: 1px solid white; */
}

.TopProduct_table_head_text_comp__Q6A5k {
  font-size: 1.25rem !important;
  color: #000 !important;
  font-weight: 400 !important;
  padding: 1.25rem 1.563rem !important;
}

.TopProduct_product_name__wns1U {
  width: 28.125rem;
  padding-left: 1.875rem !important;
  font-size: 1.125rem !important;
  color: #1b2559;
}

.TopProduct_product_des__M6KfG {
  font-size: 0.75rem !important;
  color: #626262 !important;
}

.TopProduct_error__K-eXB {
  padding: 4.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/TopProducts/TopProduct.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,6BAA6B;EAC7B,sBAAsB;EACtB,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".card {\n  padding: 0 !important;\n}\n\n.table_head {\n  background: #eff4f8;\n}\n\n.table_head_cell {\n  /* border-bottom: 1px solid white; */\n}\n\n.table_head_text_comp {\n  font-size: 1.25rem !important;\n  color: #000 !important;\n  font-weight: 400 !important;\n  padding: 1.25rem 1.563rem !important;\n}\n\n.product_name {\n  width: 28.125rem;\n  padding-left: 1.875rem !important;\n  font-size: 1.125rem !important;\n  color: #1b2559;\n}\n\n.product_des {\n  font-size: 0.75rem !important;\n  color: #626262 !important;\n}\n\n.error {\n  padding: 4.5rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `TopProduct_card__lWNp7`,
	"table_head": `TopProduct_table_head__B+X0K`,
	"table_head_cell": `TopProduct_table_head_cell__fYs6e`,
	"table_head_text_comp": `TopProduct_table_head_text_comp__Q6A5k`,
	"product_name": `TopProduct_product_name__wns1U`,
	"product_des": `TopProduct_product_des__M6KfG`,
	"error": `TopProduct_error__K-eXB`
};
export default ___CSS_LOADER_EXPORT___;
