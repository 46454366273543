// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Attributes_card__mWYSY {
  padding: 0 !important;
}

.Attributes_table_container__sE2UJ {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.Attributes_title__VCPhP {
  padding: 0.938rem;
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
}

.Attributes_list__dMeEC {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 48%;
  max-width: 25rem;
  margin-bottom: 1rem; 
}

.Attributes_list_btn__Z0YhY {
  text-align: center;
  padding: 0.938rem;
}

.Attributes_list__dMeEC > * {
  width: 100%;
  text-align: center;
}

.Attributes_list__dMeEC h6 {
  padding-bottom: 0.625rem;
}

.Attributes_error_view__u2aG1 {
  padding: 7rem 0;
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Attributes/Attributes.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".card {\n  padding: 0 !important;\n}\n\n.table_container {\n  display: flex;\n  flex-direction: row;\n  margin-top: 1rem;\n  justify-content: space-between;\n  /* flex-wrap: wrap; */\n}\n\n.title {\n  padding: 0.938rem;\n  background: #eff4f8;\n  box-shadow: 0px 3.5px 5.5px 0px #00000005;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex-basis: 48%;\n  max-width: 25rem;\n  margin-bottom: 1rem; \n}\n\n.list_btn {\n  text-align: center;\n  padding: 0.938rem;\n}\n\n.list > * {\n  width: 100%;\n  text-align: center;\n}\n\n.list h6 {\n  padding-bottom: 0.625rem;\n}\n\n.error_view {\n  padding: 7rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Attributes_card__mWYSY`,
	"table_container": `Attributes_table_container__sE2UJ`,
	"title": `Attributes_title__VCPhP`,
	"list": `Attributes_list__dMeEC`,
	"list_btn": `Attributes_list_btn__Z0YhY`,
	"error_view": `Attributes_error_view__u2aG1`
};
export default ___CSS_LOADER_EXPORT___;
