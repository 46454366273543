// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LLM_chat-window__c0pLz {
  display: flex;
  flex-direction: column;
}
.LLM_header__FEEHN {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid lightgray;
  padding: 10px 16px;
}
.LLM_img__91oSd {
  width: 17px;
  height: 17px;
  object-fit: contain;
  border-radius: 0.125rem;
}
.LLM_name__lkPWi {
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 600;
  font-size: 0.875rem;
}

.LLM_chat__p5mM1 {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.LLM_chat_content__\\+Xer1 {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/PromptEngine/LLM.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".chat-window {\n  display: flex;\n  flex-direction: column;\n}\n.header {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  border-bottom: 1px solid lightgray;\n  padding: 10px 16px;\n}\n.img {\n  width: 17px;\n  height: 17px;\n  object-fit: contain;\n  border-radius: 0.125rem;\n}\n.name {\n  padding: 0 !important;\n  margin: 0 !important;\n  font-weight: 600;\n  font-size: 0.875rem;\n}\n\n.chat {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  overflow: hidden;\n}\n\n.chat_content {\n  flex-grow: 1;\n  overflow-y: auto;\n  padding: 10px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat-window": `LLM_chat-window__c0pLz`,
	"header": `LLM_header__FEEHN`,
	"img": `LLM_img__91oSd`,
	"name": `LLM_name__lkPWi`,
	"chat": `LLM_chat__p5mM1`,
	"chat_content": `LLM_chat_content__+Xer1`
};
export default ___CSS_LOADER_EXPORT___;
