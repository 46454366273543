// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandSnippets_container__gAmjt {
  flex: 1 1;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/BrandSnippets/BrandSnippets.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,kBAAkB;AACpB","sourcesContent":[".container {\n  flex: 1;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandSnippets_container__gAmjt`
};
export default ___CSS_LOADER_EXPORT___;
