// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AIGenerated_container__8F2xF {
  background: #eff4f8;
  padding: 0 !important;
  border-radius: 3px !important;
  padding: 5px 10px !important;
}

.AIGenerated_content__CsktV {
  border-top: 1px solid white;
  padding: 15px;
  padding-top: 0;
}

.AIGenerated_header__KX4Mg {
  display: flex;
  justify-content: space-between;
}

.AIGenerated_sub_title__JcXsb {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #7a7a7a !important;
  /* padding: 15px 10px 15px 30px !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AIGenerated_text__eY5hI {
  background: #eff4f8;
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  margin-top: 20px;
  line-height: 19px;
  white-space: pre-wrap !important;
}

.AIGenerated_badge__4ihKj {
  background: #9864fb;
  font-size: 10px;
  color: white;
  font-weight: 400 !important;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 0 10px;
}

.AIGenerated_text_area__9eKtm {
  font-size: 11px;
  margin-top: 15px;
  line-height: 1.8;
  width: 100%;
  background-color: #fff;
}

.AIGenerated_edit_btn__3Jux0 {
  padding: 0px;
  margin-right: 20px;
}

.AIGenerated_ai_label_wrapper__bFgMs {
  padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/AIGenerated/AIGenerated.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,6CAA6C;EAC7C,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  background: #eff4f8;\n  padding: 0 !important;\n  border-radius: 3px !important;\n  padding: 5px 10px !important;\n}\n\n.content {\n  border-top: 1px solid white;\n  padding: 15px;\n  padding-top: 0;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.sub_title {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 16px;\n  color: #7a7a7a !important;\n  /* padding: 15px 10px 15px 30px !important; */\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.text {\n  background: #eff4f8;\n  font-size: 12px;\n  font-weight: 400;\n  color: #626262;\n  margin-top: 20px;\n  line-height: 19px;\n  white-space: pre-wrap !important;\n}\n\n.badge {\n  background: #9864fb;\n  font-size: 10px;\n  color: white;\n  font-weight: 400 !important;\n  padding: 6px 10px;\n  border-radius: 3px;\n  margin: 0 10px;\n}\n\n.text_area {\n  font-size: 11px;\n  margin-top: 15px;\n  line-height: 1.8;\n  width: 100%;\n  background-color: #fff;\n}\n\n.edit_btn {\n  padding: 0px;\n  margin-right: 20px;\n}\n\n.ai_label_wrapper {\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AIGenerated_container__8F2xF`,
	"content": `AIGenerated_content__CsktV`,
	"header": `AIGenerated_header__KX4Mg`,
	"sub_title": `AIGenerated_sub_title__JcXsb`,
	"text": `AIGenerated_text__eY5hI`,
	"badge": `AIGenerated_badge__4ihKj`,
	"text_area": `AIGenerated_text_area__9eKtm`,
	"edit_btn": `AIGenerated_edit_btn__3Jux0`,
	"ai_label_wrapper": `AIGenerated_ai_label_wrapper__bFgMs`
};
export default ___CSS_LOADER_EXPORT___;
