// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MUIChip_chip__Sl3WE {
    padding: 14px 5px !important;
    margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Component/UI/MUIChip/MUIChip.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,WAAW;AACf","sourcesContent":[".chip {\n    padding: 14px 5px !important;\n    margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": `MUIChip_chip__Sl3WE`
};
export default ___CSS_LOADER_EXPORT___;
