// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*:not(.fa-solid):not(.fa-regular):not(.fa-light):not(.fa-thin):not(.feather) {
  font-family: "Inter", "Ubuntu", "Roboto Flex", sans-serif !important;
}

/* body {
  margin: 0;
  font-family: "Outfit" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  font-weight: 400;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen {
  .print-only {
    display: none !important;
  }

  .no-print {
    display: block;
  }
}
@media print {
  .print-only {
    display: block;
  }
  .no-print {
    display: none !important;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAIA;EACE,oEAAoE;AACtE;;AAEA;;;;;GAKG;;AAEH;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;aACW;AACb;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,cAAc;EAChB;AACF;AACA;EACE;IACE,cAAc;EAChB;EACA;IACE,wBAAwB;EAC1B;;EAEA;;IAEE,0BAA0B;IAC1B,4BAA4B;IAC5B,iCAAiC;EACnC;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,wBAAwB;EAC1B;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap\");\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n\n*:not(.fa-solid):not(.fa-regular):not(.fa-light):not(.fa-thin):not(.feather) {\n  font-family: \"Inter\", \"Ubuntu\", \"Roboto Flex\", sans-serif !important;\n}\n\n/* body {\n  margin: 0;\n  font-family: \"Outfit\" !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n} */\n\nbody {\n  font-weight: 400;\n  font-size: 14px;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n@media screen {\n  .print-only {\n    display: none !important;\n  }\n\n  .no-print {\n    display: block;\n  }\n}\n@media print {\n  .print-only {\n    display: block;\n  }\n  .no-print {\n    display: none !important;\n  }\n\n  html,\n  body {\n    height: initial !important;\n    overflow: initial !important;\n    -webkit-print-color-adjust: exact;\n  }\n\n  .page-break {\n    margin-top: 1rem;\n    display: block;\n    page-break-after: always;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
