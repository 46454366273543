// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Description_text__jpziX {
    font-size: 0.75rem;
    color: #626262;
    font-weight: 400;
    margin-bottom: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Description/Description.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".text {\n    font-size: 0.75rem;\n    color: #626262;\n    font-weight: 400;\n    margin-bottom: 1.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Description_text__jpziX`
};
export default ___CSS_LOADER_EXPORT___;
