// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_title__CnVYo {
  padding: 20px;
}
.Modal_content__Q\\+Nj9 {
  margin: 0px 24px 20px 24px !important;
  padding: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,qCAAqC;EACrC,yBAAyB;AAC3B","sourcesContent":[".title {\n  padding: 20px;\n}\n.content {\n  margin: 0px 24px 20px 24px !important;\n  padding: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Modal_title__CnVYo`,
	"content": `Modal_content__Q+Nj9`
};
export default ___CSS_LOADER_EXPORT___;
