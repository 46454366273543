// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Competitors_container__BnUgF {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.563rem;
  padding: 1.875rem 1.563rem;
}

.Competitors_title__kTIqd {
  padding: 0.938rem;
  border-bottom: 1px solid #e4e4e4;
}

.Competitors_card__X1zUS {
  padding: 0 !important;
}

.Competitors_error__UUZX2 {
  grid-column: span 4;
  padding: 2rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Competitors/Competitors.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4CAA4C;EAC5C,2DAA2D;EAC3D,aAAa;EACb,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".container {\n  display: grid;\n  /* grid-template-columns: 1fr 1fr 1fr 1fr; */\n  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));\n  gap: 1.563rem;\n  padding: 1.875rem 1.563rem;\n}\n\n.title {\n  padding: 0.938rem;\n  border-bottom: 1px solid #e4e4e4;\n}\n\n.card {\n  padding: 0 !important;\n}\n\n.error {\n  grid-column: span 4;\n  padding: 2rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Competitors_container__BnUgF`,
	"title": `Competitors_title__kTIqd`,
	"card": `Competitors_card__X1zUS`,
	"error": `Competitors_error__UUZX2`
};
export default ___CSS_LOADER_EXPORT___;
