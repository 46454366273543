// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandReport_container__BwYcI {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.BrandReport_item__PFUyu {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  width: calc(33.333% - 40px); /* Adjust for padding and margin */
}

@media (max-width: 768px) {
  .BrandReport_item__PFUyu {
    width: calc(50% - 40px); /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .BrandReport_item__PFUyu {
    width: calc(100% - 40px); /* 1 item per row */
  }
}
`, "",{"version":3,"sources":["webpack://./src/BrandReport.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,0CAA0C;EAC1C,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,2BAA2B,EAAE,kCAAkC;AACjE;;AAEA;EACE;IACE,uBAAuB,EAAE,oBAAoB;EAC/C;AACF;;AAEA;EACE;IACE,wBAAwB,EAAE,mBAAmB;EAC/C;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.item {\n  background-color: #f2f2f2;\n  border: 1px solid #ccc;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);\n  margin: 10px;\n  padding: 20px;\n  text-align: center;\n  width: calc(33.333% - 40px); /* Adjust for padding and margin */\n}\n\n@media (max-width: 768px) {\n  .item {\n    width: calc(50% - 40px); /* 2 items per row */\n  }\n}\n\n@media (max-width: 480px) {\n  .item {\n    width: calc(100% - 40px); /* 1 item per row */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandReport_container__BwYcI`,
	"item": `BrandReport_item__PFUyu`
};
export default ___CSS_LOADER_EXPORT___;
