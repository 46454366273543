// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_navList__uxsBK {
  padding: 1rem;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.Sidebar_navItem__2xVvt {
  margin-bottom: 10px;
}

.Sidebar_navItem__2xVvt:last-of-type {
  margin-bottom: 0px;
}

.Sidebar_navLink__hYeAL {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #000;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.Sidebar_navLinkActive__oqGGm {
  background-color: #fff;
  color: #2d3748;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.Sidebar_iconWrap__jqagO {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Sidebar_iconWrapActive__nNGMp {
  background-color: #4fd1c5;
  color: #fff;
}

.Sidebar_feather__W6IsO {
  font-size: 24px;
}

.Sidebar_icon__zedcE {
 color: #2d3748;
 font-size: 17px !important;
}
.Sidebar_activeIcon__P-K5d {
  color: white;
  font-size: 17px !important;
}

.Sidebar_labelText__LjaB\\+{
  color: #2d3748;
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Component/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,mDAAmD;AACrD;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,mDAAmD;EACnD,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;CACC,cAAc;CACd,0BAA0B;AAC3B;AACA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".navList {\n  padding: 1rem;\n  margin-bottom: auto;\n  display: flex;\n  flex-direction: column;\n  list-style: none;\n}\n\n.navItem {\n  margin-bottom: 10px;\n}\n\n.navItem:last-of-type {\n  margin-bottom: 0px;\n}\n\n.navLink {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  text-decoration: none;\n  color: #000;\n  padding: 10px 16px;\n  cursor: pointer;\n  border-radius: 6px;\n  transition: all 0.3s ease;\n}\n\n.navLinkActive {\n  background-color: #fff;\n  color: #2d3748;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n}\n\n.iconWrap {\n  background-color: #fff;\n  padding: 10px;\n  border-radius: 4px;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n  width: 30px !important;\n  height: 30px !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.iconWrapActive {\n  background-color: #4fd1c5;\n  color: #fff;\n}\n\n.feather {\n  font-size: 24px;\n}\n\n.icon {\n color: #2d3748;\n font-size: 17px !important;\n}\n.activeIcon {\n  color: white;\n  font-size: 17px !important;\n}\n\n.labelText{\n  color: #2d3748;\n  font-size: 14px;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navList": `Sidebar_navList__uxsBK`,
	"navItem": `Sidebar_navItem__2xVvt`,
	"navLink": `Sidebar_navLink__hYeAL`,
	"navLinkActive": `Sidebar_navLinkActive__oqGGm`,
	"iconWrap": `Sidebar_iconWrap__jqagO`,
	"iconWrapActive": `Sidebar_iconWrapActive__nNGMp`,
	"feather": `Sidebar_feather__W6IsO`,
	"icon": `Sidebar_icon__zedcE`,
	"activeIcon": `Sidebar_activeIcon__P-K5d`,
	"labelText": `Sidebar_labelText__LjaB+`
};
export default ___CSS_LOADER_EXPORT___;
