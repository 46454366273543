// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sources_card__nj1MR {
  padding: 0 !important;
}

.Sources_title__eTzGi {
  padding: 0.938rem;
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
}

.Sources_copy_btn__PAPHG {
  margin-left: 0.313rem;
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Sources/Sources.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".card {\n  padding: 0 !important;\n}\n\n.title {\n  padding: 0.938rem;\n  background: #eff4f8;\n  box-shadow: 0px 3.5px 5.5px 0px #00000005;\n}\n\n.copy_btn {\n  margin-left: 0.313rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Sources_card__nj1MR`,
	"title": `Sources_title__eTzGi`,
	"copy_btn": `Sources_copy_btn__PAPHG`
};
export default ___CSS_LOADER_EXPORT___;
