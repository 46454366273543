// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.History_title__I2WiI {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  border-bottom: 0.5px solid #cacaca;
  padding: 1.25rem 0.938rem;

  position: sticky;
  background: white;
  top: 0;
  z-index: 10;
}
.History_card__2IcpL {
  padding: 0 !important;
  height: 11.1rem;
  overflow-y: auto;
}
.History_container__08wDG {
  padding: 1.25rem 0.938rem;
  padding-top: 0;
  overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.History_container__08wDG::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
  height: 0; /* Hides the scrollbar on the horizontal axis */
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/History/History.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,kCAAkC;EAClC,yBAAyB;;EAEzB,gBAAgB;EAChB,iBAAiB;EACjB,MAAM;EACN,WAAW;AACb;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,qBAAqB,EAAE,gBAAgB;EACvC,wBAAwB;AAC1B;AACA;EACE,QAAQ,EAAE,kCAAkC;EAC5C,SAAS,EAAE,+CAA+C;AAC5D","sourcesContent":[".title {\n  font-weight: 500 !important;\n  font-size: 0.875rem !important;\n  border-bottom: 0.5px solid #cacaca;\n  padding: 1.25rem 0.938rem;\n\n  position: sticky;\n  background: white;\n  top: 0;\n  z-index: 10;\n}\n.card {\n  padding: 0 !important;\n  height: 11.1rem;\n  overflow-y: auto;\n}\n.container {\n  padding: 1.25rem 0.938rem;\n  padding-top: 0;\n  overflow: scroll;\n  scrollbar-width: none; /* For Firefox */\n  -ms-overflow-style: none;\n}\n.container::-webkit-scrollbar {\n  width: 0; /* For Chrome, Safari, and Opera */\n  height: 0; /* Hides the scrollbar on the horizontal axis */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `History_title__I2WiI`,
	"card": `History_card__2IcpL`,
	"container": `History_container__08wDG`
};
export default ___CSS_LOADER_EXPORT___;
