// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MUISelect_box__hUXsu {
  min-width: 120px;
}

.MUISelect_label__j7Y\\+9 {
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  padding-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Component/UI/MUISelect/MUISelect.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,oCAAoC;EACpC,mBAAmB;AACrB","sourcesContent":[".box {\n  min-width: 120px;\n}\n\n.label {\n  font-size: 11px !important;\n  color: rgba(0, 0, 0, 0.6) !important;\n  padding-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `MUISelect_box__hUXsu`,
	"label": `MUISelect_label__j7Y+9`
};
export default ___CSS_LOADER_EXPORT___;
