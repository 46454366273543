// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message_message__mTtQX {
  display: flex;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  max-width: fit-content;
  position: relative;
}
.Message_assistant__LxoyH {
  background-color: rgb(242 242 242);
}
.Message_user__t7nP7 {
  background-color: rgb(73 135 252);
  color: white !important;
}

.Message_messageContainer__q4GlR {
  display: flex;
}
.Message_userContainer__89GR9 {
  flex-direction: row-reverse;
}
.Message_content__Xfw3l {
  font-size: 0.775rem;
}

.Message_copy__UFavp {
  display: inline-block;
  align-self: flex-end;
  position: absolute !important;
  background-color: gray !important;
  bottom: -16px !important;
  right: -0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/PromptEngine/Message.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,6BAA6B;EAC7B,iCAAiC;EACjC,wBAAwB;EACxB,sBAAsB;AACxB","sourcesContent":[".message {\n  display: flex;\n  padding: 5px 10px;\n  margin-bottom: 10px;\n  border-radius: 12px;\n  max-width: fit-content;\n  position: relative;\n}\n.assistant {\n  background-color: rgb(242 242 242);\n}\n.user {\n  background-color: rgb(73 135 252);\n  color: white !important;\n}\n\n.messageContainer {\n  display: flex;\n}\n.userContainer {\n  flex-direction: row-reverse;\n}\n.content {\n  font-size: 0.775rem;\n}\n\n.copy {\n  display: inline-block;\n  align-self: flex-end;\n  position: absolute !important;\n  background-color: gray !important;\n  bottom: -16px !important;\n  right: -0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `Message_message__mTtQX`,
	"assistant": `Message_assistant__LxoyH`,
	"user": `Message_user__t7nP7`,
	"messageContainer": `Message_messageContainer__q4GlR`,
	"userContainer": `Message_userContainer__89GR9`,
	"content": `Message_content__Xfw3l`,
	"copy": `Message_copy__UFavp`
};
export default ___CSS_LOADER_EXPORT___;
