// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardComponent_main-value__\\+gjWS {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  width: 35px;
}

.DashboardComponent_up-icon__bXq3O {
  margin-top: 3px;
  font-size: 12px;
  /* width: 10px; */
  /* margin-left: 2px; */

}

.DashboardComponent_down-icon__ss7Ja {
  margin-bottom: 5.5px;
  font-size: 12px;
  /* width: 10px; */
  /* margin-left: 2px; */

}

.DashboardComponent_delta__LnuNz {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 1px;
  /* width: 15px; */
  color: "#575757";
  /* margin-left: -4px; */
}

.DashboardComponent_core-main-value__NEje0 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  width: 20px;
}

.DashboardComponent_core-up-icon__1VLfI {
  margin-top: 5px;
  font-size: 13px;

}

.DashboardComponent_core-down-icon__M4dYk {
  margin-bottom: 5px;
  font-size: 13px;

}

.DashboardComponent_coreDelta__GH7zY {
  font-size: 14px;
  line-height: 1.2;
  margin-top: 1px;
}

.DashboardComponent_comp-logo-header__Iz1fK {
  width: 100px;
  vertical-align: top;
}

.DashboardComponent_comp-logos__qi3n4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.DashboardComponent_logo__MXX2C {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Dashboard/Component/DashboardComponent.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,sBAAsB;;AAExB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;;AAExB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;;AAEjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;;AAEjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".main-value {\n  font-size: 15px;\n  font-weight: 600;\n  line-height: 1.2;\n  width: 35px;\n}\n\n.up-icon {\n  margin-top: 3px;\n  font-size: 12px;\n  /* width: 10px; */\n  /* margin-left: 2px; */\n\n}\n\n.down-icon {\n  margin-bottom: 5.5px;\n  font-size: 12px;\n  /* width: 10px; */\n  /* margin-left: 2px; */\n\n}\n\n.delta {\n  font-size: 12px;\n  line-height: 1.2;\n  margin-top: 1px;\n  /* width: 15px; */\n  color: \"#575757\";\n  /* margin-left: -4px; */\n}\n\n.core-main-value {\n  font-size: 17px;\n  font-weight: 600;\n  line-height: 1.2;\n  width: 20px;\n}\n\n.core-up-icon {\n  margin-top: 5px;\n  font-size: 13px;\n\n}\n\n.core-down-icon {\n  margin-bottom: 5px;\n  font-size: 13px;\n\n}\n\n.coreDelta {\n  font-size: 14px;\n  line-height: 1.2;\n  margin-top: 1px;\n}\n\n.comp-logo-header {\n  width: 100px;\n  vertical-align: top;\n}\n\n.comp-logos {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  height: 100%;\n}\n\n.logo {\n  width: 40px;\n  height: 40px;\n  border-radius: 6px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-value": `DashboardComponent_main-value__+gjWS`,
	"up-icon": `DashboardComponent_up-icon__bXq3O`,
	"down-icon": `DashboardComponent_down-icon__ss7Ja`,
	"delta": `DashboardComponent_delta__LnuNz`,
	"core-main-value": `DashboardComponent_core-main-value__NEje0`,
	"core-up-icon": `DashboardComponent_core-up-icon__1VLfI`,
	"core-down-icon": `DashboardComponent_core-down-icon__M4dYk`,
	"coreDelta": `DashboardComponent_coreDelta__GH7zY`,
	"comp-logo-header": `DashboardComponent_comp-logo-header__Iz1fK`,
	"comp-logos": `DashboardComponent_comp-logos__qi3n4`,
	"logo": `DashboardComponent_logo__MXX2C`
};
export default ___CSS_LOADER_EXPORT___;
