// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PromptEngine_container__oezVQ {
  padding: 0 1rem;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
}

.PromptEngine_grid__egNLr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  flex-grow: 1 !important;
  overflow: hidden;
}

.PromptEngine_form__eEpk4 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.PromptEngine_textInput__\\+aE4N {
  background-color: white;
}

.PromptEngine_reset_btn__fojtE {
  color: #007aff !important;
  font-weight: 500 !important;
}

.PromptEngine_action_txt__O1Z99 {
  color: #007aff;
  font-size: 12px;
  display: block;
  text-align: end;
  padding: 0.7rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/PromptEngine/PromptEngine.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,kCAAkC;EAClC,SAAS;EACT,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".container {\n  padding: 0 1rem;\n  /* height: 100vh; */\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  margin-bottom: 1.5rem;\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  gap: 10px;\n  flex-grow: 1 !important;\n  overflow: hidden;\n}\n\n.form {\n  display: flex;\n  align-items: center;\n  margin-top: 10px;\n}\n\n.textInput {\n  background-color: white;\n}\n\n.reset_btn {\n  color: #007aff !important;\n  font-weight: 500 !important;\n}\n\n.action_txt {\n  color: #007aff;\n  font-size: 12px;\n  display: block;\n  text-align: end;\n  padding: 0.7rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PromptEngine_container__oezVQ`,
	"grid": `PromptEngine_grid__egNLr`,
	"form": `PromptEngine_form__eEpk4`,
	"textInput": `PromptEngine_textInput__+aE4N`,
	"reset_btn": `PromptEngine_reset_btn__fojtE`,
	"action_txt": `PromptEngine_action_txt__O1Z99`
};
export default ___CSS_LOADER_EXPORT___;
