import { useState, useContext, useEffect } from "react";
import { Skeleton } from "@mui/material";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

import MUICard from "./../UI/MUICard/MUICard";
import classes from "./AIGenerated.module.css";
import MUIIconButton from "../UI/MUIIconButton/MUIIconButton";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { DiscoveryContext } from "./../../luminaire/Discovery/discovery-context";
import { updateDataInFirebase } from "../../DatabaseFirebase/firebaseService";

function calculateRows(text, textareaCols) {
  const lines = text.split("\n");
  let totalRows = 0;

  lines.forEach((line) => {
    totalRows += Math.ceil(line.length / textareaCols);
  });
  return totalRows + 1;
}

export function AILabel({ edit, isEditing, onEdit, loading }) {
  return (
    <span className={classes.sub_title}>
      <span>
        <span>Summary</span>
        <span className={classes.badge}>
          <AutoFixHighOutlinedIcon sx={{ fontSize: 15 }} />
          &nbsp;&nbsp;AI Generated
        </span>
      </span>
      {edit && !loading && (
        <MUIIconButton
          onClick={onEdit}
          className={classes.edit_btn}
          data-html2canvas-ignore
        >
          {!isEditing && <DriveFileRenameOutlineOutlinedIcon />}
          {isEditing && <DoneOutlinedIcon />}
        </MUIIconButton>
      )}
    </span>
  );
}

export default function AIGenerated({
  loading,
  text,
  children,
  firebaseNode,
  edit = false,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [summary, setSummary] = useState(text);

  const discoveryCtx = useContext(DiscoveryContext);

  useEffect(() => {
    setSummary(text);
  }, [text]);

  function LoadingSkeletion() {
    const skeletionWidths = [100, 70, 90, 90, 70, 60];
    return (
      <div className="mt-3">
        {skeletionWidths.map((width, index) => {
          return (
            <Skeleton
              key={index}
              variant="text"
              width={`${width}%`}
              sx={{ fontSize: 10 }}
            />
          );
        })}
      </div>
    );
  }

  function handleEditing() {
    setIsEditing(!isEditing);
    if (isEditing) {
      if (summary.toString().trim() === text.toString().trim()) return;
      updateDataInFirebase("discovery_history", discoveryCtx.state.id, {
        ...discoveryCtx.state,
        [firebaseNode]: {
          ...discoveryCtx.state[firebaseNode],
          summary: summary,
        },
      });
      if (firebaseNode === "core_attributes") {
        discoveryCtx.updateCoreAttributes({
          ...discoveryCtx.state.core_attributes,
          summary: summary,
        });
      } else if(firebaseNode === "taxonomies") {
        discoveryCtx.updatedTaxonomies({
          ...discoveryCtx.state.taxonomies,
          summary: summary,
        });
      } else {
        discoveryCtx.updateCategoryDimensions({
          ...discoveryCtx.state.category_dimensions,
          summary: summary,
        });
      }
    }
  }

  return (
    <MUICard
      subText={
        <div className={classes.ai_label_wrapper}>
          <AILabel
            edit={edit}
            isEditing={isEditing}
            onEdit={handleEditing}
            loading={loading}
          />
        </div>
      }
      className={classes.container}
      variant="elevated"
      borderRadius={3}
    >
      <div className={classes.content}>
        {!loading && !isEditing && <div className={classes.text}>{text}</div>}
        {loading && <LoadingSkeletion />}
        {children}
        {!loading && isEditing && (
          <textarea
            className={classes.text_area}
            cols={100}
            rows={calculateRows(text, 100)}
            onChange={(e) => setSummary(e.target.value)}
            value={summary}
          ></textarea>
        )}
      </div>
    </MUICard>
  );
}
