// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_title__Rmd2R {
    font-size: 16px;
    color: #000000;
}
.settings_container__UwEOw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
}
.settings_btn__gdmy6 {
    /* width: 10px; */
}
/* .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    padding: 15px;
  } */
  .settings_select__AjnQE{
    margin-top: 15px;
    width: 100%;
  }

  .settings_divContainer__VXxYm{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }
  .settings_formGrid__\\+ixVL  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    /* width: 100%; */
     margin-top: 10px;
    /* padding: 15px; */
  }

  .settings_buttonDiv__f8Y7p{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/luminaire/Settings/settings.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,iBAAiB;AACrB;AACA;;;;;;KAMK;EACH;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;EACf;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,iBAAiB;KAChB,gBAAgB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,SAAS;IACT,gBAAgB;EAClB","sourcesContent":[".title {\n    font-size: 16px;\n    color: #000000;\n}\n.container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #f8f9fa;\n}\n.btn {\n    /* width: 10px; */\n}\n/* .formGrid {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 16px;\n    width: 100%;\n    padding: 15px;\n  } */\n  .select{\n    margin-top: 15px;\n    width: 100%;\n  }\n\n  .divContainer{\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    padding: 10px;\n  }\n  .formGrid  {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 16px;\n    /* width: 100%; */\n     margin-top: 10px;\n    /* padding: 15px; */\n  }\n\n  .buttonDiv{\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `settings_title__Rmd2R`,
	"container": `settings_container__UwEOw`,
	"btn": `settings_btn__gdmy6`,
	"select": `settings_select__AjnQE`,
	"divContainer": `settings_divContainer__VXxYm`,
	"formGrid": `settings_formGrid__+ixVL`,
	"buttonDiv": `settings_buttonDiv__f8Y7p`
};
export default ___CSS_LOADER_EXPORT___;
