import React, { useEffect, useContext, useState } from "react";
import { Skeleton } from "@mui/material";
import axios from "axios";

import { DiscoveryContext } from "../discovery-context";
import MUICard from "./../../../Component/UI/MUICard/MUICard";
import CompetitorItem from "./CompetitorItem";
import classes from "./Competitors.module.css";
import ErrorView from "../../../Component/ErrorView/ErrorView";

export default function Competitors({ category, brand }) {
  const [error, setError] = useState();

  const discoveryCtx = useContext(DiscoveryContext);

  let competitors = discoveryCtx.competitors.slice(1, 4).map((c) => c.name);

  useEffect(() => {
    if (discoveryCtx.brand_scores.length > 0) return;
    getBrandIndexScores();
  }, [discoveryCtx.brand_scores.length, competitors.length]);

  function createPromise(product) {
    const payload = {
      llm: discoveryCtx.llm,
      category: category,
      count: 5,
      brand: discoveryCtx.type === "brand" ? "companies" : "products",
      persona: "General Profile",
      return_meta: true,
      // "location": "New York",
      market: discoveryCtx.market,
      product: product,
    };
    return axios.post(
      "https://brandluminaire.eastus.cloudapp.azure.com/api/metrics/v3/get-brand-index-score",
      payload
    );
  }

  async function getBrandIndexScores() {
    setError(null);
    const promiseArr = [];

    try {
      for (let competitor of competitors) {
        if (discoveryCtx.llm === "Perplexity") {
          promiseArr.push(
            new Promise((res) => setTimeout(() => res(""), 60000))
          );
        }
        promiseArr.push(createPromise(competitor));
      }
      promiseArr.forEach((promise, index) => {
        promise
          .then((result) => {
            discoveryCtx.updateBrandScores({
              data: { ...result.data.data[0], extras: result.data.extras },
              index: index + 1,
            });
          })
          .catch((error) => {
            console.error(`Promise ${index + 1} rejected:`, error);
            discoveryCtx.updateBrandScores({
              data: error?.response?.data,
              index: index + 1,
            });
          });
      });
    } catch (error) {
      setError(error.message);
    }
  }

  async function BrandIndexRetryHandler(index) {
    console.log(competitors[index]);
    discoveryCtx.updateBrandScores({ data: null, index: index + 1 });
    const promiseArr = [];
    promiseArr.push(createPromise(competitors[index]));

    try {
      const [response] = await Promise.all(promiseArr);
      discoveryCtx.updateBrandScores({
        data: { ...response.data.data[0], extras: response.data.extras },
        index: index + 1,
      });
    } catch (error) {
      discoveryCtx.updateBrandScores({
        data: error?.response?.data,
        index: index + 1,
      });
    }
  }

  let brandData = discoveryCtx.brand_scores;

  return (
    <MUICard
      title="Competitor Analysis"
      variant="elevated"
      elevation={0}
      className={classes.card}
      titleClassName={classes.title}
      width={"100%"}
    >
      <div className={classes.container}>
        {!error &&
          competitors.map((competitor, index) => {
            return (
              <React.Fragment key={index}>
                {brandData[index + 1] ? (
                  <CompetitorItem
                    competitor={competitor}
                    brandIndex={brandData[index + 1]}
                    brand={brand}
                    index={index}
                    onRetry={BrandIndexRetryHandler}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    style={{
                      flexGrow: 1,
                      height: 220,
                      flexBasis: "calc(50% - 12.5px)",
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        {!error && <CompetitorItem />}
        {error && (
          <div className={classes.error}>
            <ErrorView
              title={"Error fetching competitor analysis"}
              message={error}
              retry
              onRetry={() => getBrandIndexScores()}
            />
          </div>
        )}
      </div>
    </MUICard>
  );
}
