// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageList_message-list__VyJds {
  height: 20rem;
  max-height: 20rem;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/PromptEngine/MessageList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".message-list {\n  height: 20rem;\n  max-height: 20rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message-list": `MessageList_message-list__VyJds`
};
export default ___CSS_LOADER_EXPORT___;
