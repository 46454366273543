// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Summary_box__FyeUh {
  margin-top: 0.6rem;
  background-color: #fff;
  border: 1px solid hsl(215, 15%, 92%);
}
.Summary_title__3vzyd {
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  background-color: #f7f7f7 !important;
}

.Summary_text__pjmiS {
  padding-top: 0.4rem;
  font-size: 0.89rem;
}

.Summary_regenerate__IVZ6G {
  color: #007aff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Summary_header__q7o8t {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/PromptEngine/Summary.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,oCAAoC;AACtC;AACA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,sBAAsB;AACxB","sourcesContent":[".box {\n  margin-top: 0.6rem;\n  background-color: #fff;\n  border: 1px solid hsl(215, 15%, 92%);\n}\n.title {\n  font-weight: 600 !important;\n  font-size: 1.1rem !important;\n  background-color: #f7f7f7 !important;\n}\n\n.text {\n  padding-top: 0.4rem;\n  font-size: 0.89rem;\n}\n\n.regenerate {\n  color: #007aff !important;\n  font-size: 12px !important;\n  font-weight: 600 !important;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid #ececec;\n  padding-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `Summary_box__FyeUh`,
	"title": `Summary_title__3vzyd`,
	"text": `Summary_text__pjmiS`,
	"regenerate": `Summary_regenerate__IVZ6G`,
	"header": `Summary_header__q7o8t`
};
export default ___CSS_LOADER_EXPORT___;
