// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_input_group__-OGM8 {
  display: flex;
  flex-direction: row;
  gap: 0.938rem;
  margin-top: 1.875rem;
  justify-content: space-between;
  align-items: flex-end;
}

.Form_input_group__-OGM8 > * {
  flex-grow: 1;
}

.Form_form__00EyN {
  padding: 1.25rem;
  background-color: white;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Form.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,oBAAoB;EACpB,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mDAAmD;EACnD,kBAAkB;AACpB","sourcesContent":[".input_group {\n  display: flex;\n  flex-direction: row;\n  gap: 0.938rem;\n  margin-top: 1.875rem;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n\n.input_group > * {\n  flex-grow: 1;\n}\n\n.form {\n  padding: 1.25rem;\n  background-color: white;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_group": `Form_input_group__-OGM8`,
	"form": `Form_form__00EyN`
};
export default ___CSS_LOADER_EXPORT___;
