// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoreAttributes_card__hiDfB {
  padding: 0 !important;
}

.CoreAttributes_attribute_headings__69LHS {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #333 !important;
  text-align: center !important;
}

.CoreAttributes_attribute_score__hmlE4 {
  font-size: 1rem !important;
  text-align: center !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.CoreAttributes_table_head__tGwhZ {
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid white !important;
}
.CoreAttributes_table_head_cell__JEeN6 {
  padding-top: 0.938rem !important;
  padding-bottom: 0.625rem !important;
  border-bottom: 1px solid white;
}
.CoreAttributes_table_head_content__5Fzvo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CoreAttributes_table_head_logo__UAu2l {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
}
.CoreAttributes_table_head_text__1Pi-s {
  font-size: 0.875rem;
  color: #808080;
  font-weight: 300;
  padding-top: 0.313rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7.5rem;
}

.CoreAttributes_table_head_text_comp__vDXv0 {
  font-size: 1.25rem;
  color: #000;
  font-weight: 500;
}

.CoreAttributes_error__nLJVw {
  padding: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/CoreAttributes/CoreAttributes.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,mDAAmD;EACnD,yCAAyC;AAC3C;AACA;EACE,gCAAgC;EAChC,mCAAmC;EACnC,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,qBAAqB;;EAErB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".card {\n  padding: 0 !important;\n}\n\n.attribute_headings {\n  font-size: 0.875rem !important;\n  font-weight: 500 !important;\n  color: #333 !important;\n  text-align: center !important;\n}\n\n.attribute_score {\n  font-size: 1rem !important;\n  text-align: center !important;\n  font-weight: 700 !important;\n  cursor: pointer !important;\n}\n\n.table_head {\n  background: #eff4f8;\n  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);\n  border-bottom: 1px solid white !important;\n}\n.table_head_cell {\n  padding-top: 0.938rem !important;\n  padding-bottom: 0.625rem !important;\n  border-bottom: 1px solid white;\n}\n.table_head_content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.table_head_logo {\n  width: 2.5rem;\n  height: 2.5rem;\n  border-radius: 6px;\n}\n.table_head_text {\n  font-size: 0.875rem;\n  color: #808080;\n  font-weight: 300;\n  padding-top: 0.313rem;\n\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 7.5rem;\n}\n\n.table_head_text_comp {\n  font-size: 1.25rem;\n  color: #000;\n  font-weight: 500;\n}\n\n.error {\n  padding: 2rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CoreAttributes_card__hiDfB`,
	"attribute_headings": `CoreAttributes_attribute_headings__69LHS`,
	"attribute_score": `CoreAttributes_attribute_score__hmlE4`,
	"table_head": `CoreAttributes_table_head__tGwhZ`,
	"table_head_cell": `CoreAttributes_table_head_cell__JEeN6`,
	"table_head_content": `CoreAttributes_table_head_content__5Fzvo`,
	"table_head_logo": `CoreAttributes_table_head_logo__UAu2l`,
	"table_head_text": `CoreAttributes_table_head_text__1Pi-s`,
	"table_head_text_comp": `CoreAttributes_table_head_text_comp__vDXv0`,
	"error": `CoreAttributes_error__nLJVw`
};
export default ___CSS_LOADER_EXPORT___;
