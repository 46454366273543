// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandInsights_brand__ufeM4 {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}

.BrandInsights_brand_specs__GWu2t {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.625rem;
}

.BrandInsights_partition__Fwb5b {
  border-left: 1px solid #d9d9d9;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/BrandInsights/BrandInsights.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mCAAmC;EACnC,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":[".brand {\n  border: 1px solid #d9d9d9;\n  border-radius: 8px;\n  padding: 1.25rem;\n  margin-bottom: 1.25rem;\n}\n\n.brand_specs {\n  display: grid;\n  grid-template-columns: 1fr auto 1fr;\n  gap: 0.625rem;\n}\n\n.partition {\n  border-left: 1px solid #d9d9d9;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand": `BrandInsights_brand__ufeM4`,
	"brand_specs": `BrandInsights_brand_specs__GWu2t`,
	"partition": `BrandInsights_partition__Fwb5b`
};
export default ___CSS_LOADER_EXPORT___;
