// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandUserInput_container__4k\\+r2 {
    position: relative;
    margin: 12px 0px;
}
.BrandUserInput_loading__GIHvF {
  position: absolute;
  top: 10px;
  right: 5px;
}
.BrandUserInput_error__8R4YU {
    font-size: 12px;
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/Component/BrandUserInput/BrandUserInput.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".container {\n    position: relative;\n    margin: 12px 0px;\n}\n.loading {\n  position: absolute;\n  top: 10px;\n  right: 5px;\n}\n.error {\n    font-size: 12px;\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandUserInput_container__4k+r2`,
	"loading": `BrandUserInput_loading__GIHvF`,
	"error": `BrandUserInput_error__8R4YU`
};
export default ___CSS_LOADER_EXPORT___;
