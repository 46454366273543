import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { ToastContainer, toast } from "react-toastify";

import LLM from "./LLM";
import classes from "./PromptEngine.module.css";
import MUIIconButton from "../../Component/UI/MUIIconButton/MUIIconButton";
import TextInput from "../../Component/UI/TextInput/TextInput";
import Summary from "./Summary";
import { useDailyLimits } from "../../hooks/useDailyLimits";

const LLMs = [
  {
    id: 1,
    name: "GPT-4",
    model: "gpt-4o",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://cdn.oaistatic.com/assets/apple-touch-icon-mz9nytnj.webp",
    text: "",
    messages: [],
  },
  {
    id: 2,
    name: "Gemini",
    model: "Gemini",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://www.gstatic.com/lamda/images/gemini_sparkle_v002_d4735304ff6292a690345.svg",
    text: "",
    messages: [],
  },
  {
    id: 3,
    name: "Claude",
    model: "Claude3",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://claude.ai/images/claude_app_icon.png",
    text: "",
    messages: [],
  },
  {
    id: 4,
    name: "Perplexity",
    model: "Perplexity",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://mintlify.s3-us-west-1.amazonaws.com/perplexity/_generated/favicon/favicon-32x32.png?v=3",
    text: "",
    messages: [],
  },
];

export default function Dashboard() {
  const [messageList, setMessageList] = useState(LLMs.slice());
  const [userPrompt, setUserPrompt] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { launchText, launchesRemaining, updateLaunches, isSpecial } =
    useDailyLimits("PromptEngine", 20);

  const { pathname } = useLocation();

  useEffect(() => {
    resetConversation();
  }, [pathname]);

  const handleSend = (e) => {
    e.preventDefault();
    if (!userPrompt || (launchesRemaining == 0 && !isSpecial)) {
      toast.warn("You have reached your daily limit")
      return;
    }

    if (!isSpecial) updateLaunches();
    
    setLoading(true);
    const newMessageList = [...messageList];
    newMessageList.forEach((llm) => {
      llm.messages.push({
        id: crypto.randomUUID(),
        role: "user",
        content: userPrompt,
      });
    });
    setMessageList(newMessageList);
    setUserPrompt("");
  };

  function resetConversation() {
    if(isLoading) return;
    const updatedMessages = LLMs.slice().map((item) => {
      return {
        ...item,
        messages: [],
      };
    });
    setMessageList(updatedMessages);
  }

  function handleMessageReceived(llmName, message) {
    const newMessageList = [...messageList];
    const llmIndex = newMessageList.findIndex((llm) => llm.name === llmName);
    newMessageList[llmIndex].messages.push(message);
    setMessageList(newMessageList);
  }

  const messages = messageList.map((list) => list.messages);

  useEffect(() => {
    if (messages.every((msg) => msg.length === 0)) {
      setLoading(false);
      return;
    }
    const isDataArrived = messages.every(
      (msgs) => msgs.slice(-1)[0]?.role == "assistant"
    );
    if (isDataArrived) setLoading(false);
  }, [messages]);

  return (
    <div className={classes.container}>
      <ToastContainer />
      <span className={classes.action_txt}>{launchText}</span>
      <div className={classes.grid}>
        {messageList.map((llm) => (
          <LLM key={llm.name} llm={llm} onMessage={handleMessageReceived} />
        ))}
      </div>
      <Summary
        messages={messageList.map((list) => ({
          msgs: list.messages,
          llm: list.name,
        }))}
        loading={isLoading}
      />
      <form className={classes.form} onSubmit={handleSend}>
        <TextInput
          fullWidth
          variant="outlined"
          placeholder="Ask AI a question or make a request..."
          value={userPrompt}
          className={classes.textInput}
          onChange={(e) => setUserPrompt(e.target.value)}
        />
        <MUIIconButton
          loading={isLoading}
          variant="contained"
          sx={{ mx: 1 }}
          type="submit"
        >
          <SendOutlinedIcon />
        </MUIIconButton>
        <MUIIconButton
          className={classes.reset_btn}
          variant="outlined"
          disabled={isLoading}
          onClick={resetConversation}
        >
          <RestartAltOutlinedIcon />
        </MUIIconButton>
      </form>
    </div>
  );
}
