// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LLMIndicator_container__y0PWK {
  background-color: #ebf8ff;
  display: inline-flex;
  align-items: center;
  color: #2b6cb0;
  font-weight: 500;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.313rem 0.625rem;
  gap: 0.313rem;
}

.LLMIndicator_icon__tGeJL {
  width: 1.5rem;
  height: 1.5rem;
}

.LLMIndicator_truncate__KHqxi {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.688rem;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/LLMIndicator/LLMIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,wCAAwC;EACxC,0BAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".container {\n  background-color: #ebf8ff;\n  display: inline-flex;\n  align-items: center;\n  color: #2b6cb0;\n  font-weight: 500;\n  border-radius: 0.375rem;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n  padding: 0.313rem 0.625rem;\n  gap: 0.313rem;\n}\n\n.icon {\n  width: 1.5rem;\n  height: 1.5rem;\n}\n\n.truncate {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font-size: 0.688rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LLMIndicator_container__y0PWK`,
	"icon": `LLMIndicator_icon__tGeJL`,
	"truncate": `LLMIndicator_truncate__KHqxi`
};
export default ___CSS_LOADER_EXPORT___;
