// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PDFHeader_container__op22Y {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.PDFHeader_logo__qVywp {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.PDFHeader_title__WEoQn {
    display: flex;
    align-items: center;
}

.PDFHeader_header__kMZzN {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Component/PDFHeader/PDFHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n.logo {\n    width: 30px;\n    height: 30px;\n    margin: 0 10px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n}\n\n.header {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PDFHeader_container__op22Y`,
	"logo": `PDFHeader_logo__qVywp`,
	"title": `PDFHeader_title__WEoQn`,
	"header": `PDFHeader_header__kMZzN`
};
export default ___CSS_LOADER_EXPORT___;
