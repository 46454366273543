// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SourceItem_list_item__5SUTE {
  height: 1.875rem;
  padding: 1.563rem 0.875rem !important;
}

.SourceItem_list_item__5SUTE:hover {
  background-color: transparent;
}

.SourceItem_link__uWMCX {
  padding: 0.313rem 0.5rem;
  font-size: 1.125rem;
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Discovery/Sources/SourceItem.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qCAAqC;AACvC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".list_item {\n  height: 1.875rem;\n  padding: 1.563rem 0.875rem !important;\n}\n\n.list_item:hover {\n  background-color: transparent;\n}\n\n.link {\n  padding: 0.313rem 0.5rem;\n  font-size: 1.125rem;\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list_item": `SourceItem_list_item__5SUTE`,
	"link": `SourceItem_link__uWMCX`
};
export default ___CSS_LOADER_EXPORT___;
