// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_table__2aMGv tbody, td, tfoot, th, thead, tr {
    border-width: 0 !important
}
.Table_active__erxsA, .Table_active__erxsA svg {
    color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/Component/UI/Table/Table.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".table tbody, td, tfoot, th, thead, tr {\n    border-width: 0 !important\n}\n.active, .active svg {\n    color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__2aMGv`,
	"active": `Table_active__erxsA`
};
export default ___CSS_LOADER_EXPORT___;
