// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Brand_brand_product__9W\\+mn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Brand_brand_product__9W\\+mn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Brand_status__DT1oe {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.Brand_action_btn__zPl8C {
    padding: 0 8px !important;
}
.Brand_competitor__8aSFZ {
    padding: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/luminaire/Settings/Brand/Brand.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB","sourcesContent":[".brand_product {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.brand_product img {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n}\n.status {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n}\n.action_btn {\n    padding: 0 8px !important;\n}\n.competitor {\n    padding: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand_product": `Brand_brand_product__9W+mn`,
	"status": `Brand_status__DT1oe`,
	"action_btn": `Brand_action_btn__zPl8C`,
	"competitor": `Brand_competitor__8aSFZ`
};
export default ___CSS_LOADER_EXPORT___;
