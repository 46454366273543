// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_title__7eJsW {
    font-size: 16px;
    color: #000000;
    margin: 0;
}
.Header_container__S4gJq {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
}
.Header_btn__gAe3H {
    /* width: 130px; */
}
.Header_action_txt__yxiQb {
    color: #007AFF;
    font-size: 12px;
}
.Header_formGrid__14cnC {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    padding: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/Component/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,SAAS;AACb;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,WAAW;IACX,aAAa;EACf","sourcesContent":[".title {\n    font-size: 16px;\n    color: #000000;\n    margin: 0;\n}\n.container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #f8f9fa;\n}\n.btn {\n    /* width: 130px; */\n}\n.action_txt {\n    color: #007AFF;\n    font-size: 12px;\n}\n.formGrid {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 16px;\n    width: 100%;\n    padding: 15px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Header_title__7eJsW`,
	"container": `Header_container__S4gJq`,
	"btn": `Header_btn__gAe3H`,
	"action_txt": `Header_action_txt__yxiQb`,
	"formGrid": `Header_formGrid__14cnC`
};
export default ___CSS_LOADER_EXPORT___;
